module.exports = [{
      plugin: require('../node_modules/@onegeo-suite/gatsby-plugin-auth/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/@onegeo-suite/gatsby-theme-onegeo/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../plugins/gatsby-source-onegeosuite/gatsby-browser.js'),
      options: {"plugins":[],"url":"http://onegeo-suite"},
    },{
      plugin: require('../plugins/gatsby-source-openagenda/gatsby-browser.js'),
      options: {"plugins":[],"agendaUid":"58197249","publicKey":"8461c7731000419b8a293d0a0450fd3e","params":{"size":100,"detailed":1,"monolingual":"fr","longDescriptionFormat":"HTML","includeFields":["uid","title","updatedAt","image.base","image.filename","dateRange","firstTiming.begin","location.name","location.address","conditions","registration","description","longDescription","slug"]}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
